:root {
  --primary-maroon: #800000;
  --primary-white: #ffffff;
  --maroon-light: #b30000; /* Lighter maroon for gradient */
}

html, body {
  font-family: 'Arial', sans-serif;
  color: var(--primary-maroon);
  height: 100%;
}



header, footer {
  width: 100%;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
}


footer {
  background-color: var(--primary-maroon);
  color: var(--primary-white);
  text-align: center;
  padding: 10px 0; /* Add some padding */
  
}